var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resource"},[_c('Header',{attrs:{"type":2}}),_c('div',{staticClass:"resource-main"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-main"},[_vm._m(0),_vm._l((_vm.leftList),function(item,index1){return _c('div',{key:item.id},[(item.children)?_c('div',{staticClass:"title1-box"},[_c('div',{staticClass:"title1-name"},[_vm._v(_vm._s(item.title))]),_vm._l((item.children),function(cItem,index){return _c('div',{key:index},[(cItem.children)?_c('div',[_c('div',{staticClass:"title2-blod"},[_c('div',[_vm._v(_vm._s(cItem.title))]),_c('img',{class:['drop', { 'drop-active': cItem.drop }],attrs:{"src":require("@/assets/images/home/drop.png"),"alt":"图片"},on:{"click":function($event){return _vm.changeDrop(index1, index)}}})]),_c('div',{class:['title3-box', { 'title3-box-active': cItem.drop }]},_vm._l((cItem.children),function(item3,index3){return _c('div',{key:item3.id,class:[
                      'title3-name',
                      {
                        'title-active':
                          index1 === _vm.leftIndexs[0] &&
                          index === _vm.leftIndexs[1] &&
                          index3 === _vm.leftIndexs[2],
                      },
                    ],on:{"click":function($event){return _vm.leftSide(item3.id, 3, index1, index, index3)}}},[_vm._v(" "+_vm._s(item3.title)+" ")])}),0)]):_c('div',{class:[
                  'title2-name',
                  {
                    'title-active':
                      index1 === _vm.leftIndexs[0] && index === _vm.leftIndexs[1],
                  },
                ],on:{"click":function($event){return _vm.leftSide(cItem.id, 2, index1, index)}}},[_vm._v(" "+_vm._s(cItem.title)+" ")])])})],2):_c('div',{class:['title1', { 'title-active': index1 === _vm.leftIndexs[0] }],on:{"click":function($event){return _vm.leftSide(item.id, 1, index1)}}},[_vm._v(" "+_vm._s(item.title)+" ")])])})],2)]),_c('div',{staticClass:"centre"},[_c('div',{staticClass:"centre-main",domProps:{"innerHTML":_vm._s(_vm.centre)}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-main"},_vm._l((_vm.rightList),function(item,index){return _c('div',{key:index,class:['title', { 'right-active': _vm.rightIndex === index }],on:{"click":function($event){return _vm.rightSide(index, item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search"},[_c('input',{attrs:{"type":"text","placeholder":"搜索"}}),_c('img',{attrs:{"src":require("@/assets/images/home/search.png"),"alt":"图片"}})])
}]

export { render, staticRenderFns }