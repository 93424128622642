<template>
  <div class="resource">
    <Header :type="2"></Header>
    <div class="resource-main">
      <div class="left">
        <div class="left-main">
          <!-- 搜索 -->
          <div class="search">
            <input type="text" placeholder="搜索" />
            <img src="@/assets/images/home/search.png" alt="图片" />
          </div>

          <div v-for="(item, index1) in leftList" :key="item.id">
            <!-- 第一层 -->
            <div v-if="item.children" class="title1-box">
              <div class="title1-name">{{ item.title }}</div>
              <!-- 第二层 -->
              <div v-for="(cItem, index) in item.children" :key="index">
                <!-- 第三层 -->
                <div v-if="cItem.children">
                  <div class="title2-blod">
                    <div>{{ cItem.title }}</div>
                    <img
                      @click="changeDrop(index1, index)"
                      :class="['drop', { 'drop-active': cItem.drop }]"
                      src="@/assets/images/home/drop.png"
                      alt="图片"
                    />
                  </div>
                  <div
                    :class="['title3-box', { 'title3-box-active': cItem.drop }]"
                  >
                    <div
                      v-for="(item3, index3) in cItem.children"
                      :key="item3.id"
                      :class="[
                        'title3-name',
                        {
                          'title-active':
                            index1 === leftIndexs[0] &&
                            index === leftIndexs[1] &&
                            index3 === leftIndexs[2],
                        },
                      ]"
                      @click="leftSide(item3.id, 3, index1, index, index3)"
                    >
                      {{ item3.title }}
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  :class="[
                    'title2-name',
                    {
                      'title-active':
                        index1 === leftIndexs[0] && index === leftIndexs[1],
                    },
                  ]"
                  @click="leftSide(cItem.id, 2, index1, index)"
                >
                  {{ cItem.title }}
                </div>
              </div>
            </div>
            <div
              v-else
              :class="['title1', { 'title-active': index1 === leftIndexs[0] }]"
              @click="leftSide(item.id, 1, index1)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="centre">
        <div class="centre-main" v-html="centre"></div>
      </div>
      <div class="right">
        <div class="right-main">
          <div
            :class="['title', { 'right-active': rightIndex === index }]"
            v-for="(item, index) in rightList"
            :key="index"
            @click="rightSide(index, item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header/index.vue";
import {
  getLeftNavs,
  getSourceRightNavs,
  getSourceContent,
  getLeftNavsId,
} from "@/api/user";
export default {
  components: { Header },
  data() {
    return {
      leftList: [],
      leftIndexs: [0], //左侧选中导航
      leftNavId: 1, //左侧导航id
      rightNavId: 1, //右侧导航id,
      centre: null,
      rightList: [],
      rightIndex: 0,
    };
  },
  created() {
    if (this.$route.query.index) {
      this.leftIndexs = [4];
      this.leftIndexs.push(parseInt(this.$route.query.index - 1));
      this.leftNavId = parseInt(this.$route.query.id);
    }
    this.getLeftNavs();
  },
  methods: {
    changeDrop(index, index1) {
      if (!this.leftList[index].children[index1].drop) {
        this.leftList[index].children[index1].drop = true;
      } else {
        this.leftList[index].children[index1].drop = false;
      }
      this.leftList = this.leftList.concat();
      console.log(11111, this.leftList[index].children[index1]);
    },
    //点击左侧导航
    leftSide(id, type, index1, index2, index3) {
      switch (type) {
        case 1:
          this.leftIndexs = [];
          this.leftIndexs.push(index1);
          break;
        case 2:
          this.leftIndexs = [];
          this.leftIndexs.push(index1);
          this.leftIndexs.push(index2);

          break;
        case 3:
          this.leftIndexs = [];
          this.leftIndexs.push(index1);
          this.leftIndexs.push(index2);
          this.leftIndexs.push(index3);
          break;
      }
      this.leftNavId = id;
      this.rightNavId = 1;
      this.rightIndex = 0;
      console.log(" this.leftIndexs", this.leftIndexs);
      this.getLeftNavsId();
    },
    //点击右侧导航
    rightSide(index, id) {
      this.rightIndex = index;
      this.rightNavId = id;
      this.handleScroll(this.rightIndex);
    },
    //获取左侧导航内容
    getLeftNavs() {
      getLeftNavs().then((res) => {
        console.log("左侧", res);
        if (res.status === 200) {
          this.leftList = res.data;
          this.getLeftNavsId();
        }
      });
    },
    //点击左侧导航获取内容和锚点
    getLeftNavsId() {
      const params = {
        leftNavId: this.leftNavId,
      };
      getLeftNavsId(params).then((res) => {
        console.log("全部内容", res);
        if (res.status === 200) {
          this.rightList = res.data[0].anchors;
          this.centre = res.data[0].content;
        }
      });
    },
    handleScroll(index) {
      var element = document.getElementsByTagName("h2");
      element[index].scrollIntoView();
    },
  },
};
</script>
<style lang="scss" scoped>
.resource-main {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 70px);
  .left {
    width: 230px;
    height: calc(100vh - 70px);
    border-right: 1px solid #dddddd;
  }
  .centre {
    width: calc(100% - 432px);
    height: calc(100vh - 70px);
  }
  .right {
    width: 200px;
    height: calc(100vh - 70px);
    border-left: 1px solid #dddddd;
  }
}
.left-main {
  height: calc(100vh - 70px);
  overflow-y: auto;
  .search {
    margin-top: 17px;
    margin-left: 20px;
    position: relative;
    input {
      width: 146px;
      border: 1px solid #4578ff;
      height: 32px;
      border-radius: 32px;
      padding-left: 12px;
      padding-right: 40px;
    }
    img {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 8px;
      right: 30px;
    }
  }
  .title1 {
    font-size: 14px;
    color: #545a6a;
    margin-top: 25px;
    margin-left: 20px;
    cursor: pointer;
  }
  .title1-box {
    margin-left: 20px;
    margin-top: 25px;
    .title1-name {
      color: #545a6a;
      font-weight: bold;
      font-size: 16px;
    }
    .title2-name {
      margin-left: 38px;
      font-size: 14px;
      margin-top: 20px;
      color: #545a6a;
      cursor: pointer;
    }
    .title2-blod {
      display: flex;
      align-items: center;
      margin-top: 24px;
      div {
        font-size: 16px;
        font-weight: bold;
        color: #545a6a;
        margin-left: 21px;
      }
      .drop {
        width: 12px;
        height: 12px;
        margin-left: 20px;
        cursor: pointer;
        transition: transform 0.3s;
        transform: rotate(0deg);
      }
      .drop-active {
        transform: rotate(-180deg);
      }
    }
    .title3-box {
      max-height: 200px;
      overflow: hidden;
      transition: max-height 0.3s;
    }
    .title3-box-active {
      max-height: 0;
      overflow: hidden;
    }
    .title3-name {
      font-size: 14px;
      color: #545a6a;
      margin-left: 38px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
  .title-active {
    color: #4578ff !important;
  }
}
.centre-main {
  height: calc(100vh - 70px);
  overflow-y: auto;
  width: calc(100% - 113px);
  padding-left: 48px;
  padding-right: 65px;
  /deep/ h2 {
    color: #cb1212;
  }
}
.right-main {
  height: calc(100vh - 70px);
  .title {
    font-size: 14px;
    color: #545a6a;
    margin-top: 12px;
    margin-left: 25px;
    cursor: pointer;
  }
  .right-active {
    color: #4578ff;
  }
}
</style>